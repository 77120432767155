.Footer {
    height: fit-content;
    width: 100%;
    margin-top: 80px;
    background: rgb(0, 0, 100);
    color: white;
}
.contact-detail {
    margin: 20px;
}
.contact-detail a {
    text-decoration: none;
    color: white;
    line-height: 2;
    position: relative;
    padding: 5px 0px 5px 35px;
}
.contact-detail a:hover {
    color: black;
    background: white;
}
.contact-detail a svg {
    position: absolute;
    left: 0;
    top: 5px;
}

.announcement {
    background: blue;
    color: white;
    height: 40px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    z-index: -100;
}
.head {
    background: red;
    width: fit-content;
    padding: 10px 3px;
    height: 40px;
    z-index: 1000;
}
.notify {
    position: absolute;
    top: 7px;
    width: fit-content;
    height: 40px;
    animation: scroll 30s infinite cubic-bezier(.27,.76,.85,.63);
    z-index: -10;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.allign {
    text-align: justify;
}
.News {
    min-height: fit-content;
    max-height: 400px;
    overflow-y: scroll;
}
.login-form {
    width: 300px;
    font-size: 1.5rem;
}
.toRight {
    display: flex;
    justify-content: flex-end;
}

table,
    th,
    td {
    border: 2px solid black;
    border-collapse: collapse;
    }
    th,
    td {
    padding: 15px;
    }
    span {
    font-weight: bold;
    }

@keyframes scroll {
    from {
        right: -150vw;
    }
    to {
        right: 100vw;
    }
}

@media (max-width: 500) {
    .notify {
        animation: scroll 20s infinite cubic-bezier(.27,.76,.85,.63);
    }
}